import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Modal from "../Components/Modal";
import pdfToText from "react-pdftotext";

import uploadImg from '../images/upload.png'
import './css/FileUploadModal.css'

const fileTypes = ["PDF"];

const FileUploadModal = ({ 
  user, 
  isOpen, 
  onClose, 
  setMessage, 
  setSubject, 
  generateEmailTemplate
}) => {
  const [file, setFile] = useState(null);

  const handleFileUploadChange = async (file) => {
    setFile(file);
    onClose();

    try {
      const text = await pdfToText(file);
      generateEmailTemplate(text);
    } catch (e) {
      console.error("Error reading PDF:", e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='file-mask'>
        <div className='mask'>
          <img src={uploadImg} alt="Upload" />
          <p>upload resume here</p>
        </div>
        <FileUploader
          handleChange={handleFileUploadChange}
          name="file"
          types={fileTypes}
        />
      </div>
    </Modal>
  );
};

export default FileUploadModal;
