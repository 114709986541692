import React, { useState, useEffect } from "react";
import NavBar from "../Components/NavBar";
import "./css/Companies.css";

const ContactModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>Contact Us</h2>
        <p>Fill out the form: <a href="https://forms.gle/JruzCnoDHnrXKfJi6" target="_blank" rel="noopener noreferrer">here</a></p>
        <button className="modal-close" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

const CompanyRow = ({ company }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Sort formats by accuracy but only show the format
  const sortedFormats = [...company.email_formats].sort((a, b) => b.accuracy - a.accuracy);

  return (
    <div className="company-item" onClick={() => setIsExpanded(!isExpanded)}>
      <div className="company-header">
        <h3 className="company-name">{company.name}</h3>
        <span className={`expand-arrow ${isExpanded ? 'expanded' : ''}`}>▼</span>
      </div>
      {isExpanded && (
        <div className="formats-list">
          {sortedFormats.map((format, idx) => (
            <div key={idx} className="format-row">
              <span className="format-pattern">{format.email_format}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Companies = ({ inHome }) => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const getCompanies = async () => {
      const baseURL = process.env.REACT_APP_API_URL;

      try {
        const response = await fetch(`${baseURL}/getCompanies`);
        const data = await response.json();
        setCompanies(data.data);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };

    getCompanies();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredCompanies = companies.filter(company =>
    company.name.toLowerCase().includes(searchQuery)
  );

  return (
    <div className={`companies-wrapper ${inHome ? 'in-home' : ''}`}>
      {!inHome && <NavBar />}
      <div className="companies-container">
        <div className="intro-text">
          <h2>Company Email Format Directory</h2>
          <p className="intro-description">
            Browse our curated list of companies supported by Nodes for LinkedIn outreach. 
            These companies can be filtered through our Chrome Extension, and clicking any row 
            reveals the email formats we use. Don't see a company you need?{' '}
            <a onClick={() => setIsModalOpen(true)} className="highlight-link">
              Request it here
            </a>.
          </p>
        </div>

        <div className="search-bar">
          <input
            type="text"
            placeholder="Search companies..."
            onChange={handleSearch}
            className="search-input"
          />
        </div>

        {loading ? (
          <div className="loading">Loading...</div>
        ) : (
          <div className="results-container">
            {filteredCompanies.map((company) => (
              <CompanyRow key={company.id} company={company} />
            ))}
          </div>
        )}

        <ContactModal 
          isOpen={isModalOpen} 
          onClose={() => setIsModalOpen(false)} 
        />
      </div>
    </div>
  );
};

export default Companies;
