import React, { useEffect, useState } from "react";

import defaultImg from "../images/default-profile.png";
import SaveToCSV from "../Components/SaveToCSV";
import deleteIcon from "../images/delete.png";
import DeleteGroupModal from "./DeleteGroupModal";

import "./css/ScrapedResultsList.css";

const months_list = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const GroupRow = ({
  user,
  groupName,
  date,
  results,
  isExpanded,
  onToggle,
  deleteGroup,
  setDeleteGroup,
  deleteGroupModal,
  setDeleteGroupModal,
}) => {
  const formattedDate = date
    ? `${months_list[date.month - 1]} ${date.day}, ${date.year}`
    : "---";

  const handleHeaderClick = (e) => {
    // Don't toggle if clicking the delete button
    if (e.target.closest(".delete-btn")) {
      return;
    }
    onToggle();
  };

  const handleGroupDelete = async (e) => {
    setDeleteGroupModal(false);

    try {
      const response = await fetch(
        `https://api.thenodes.ca/delete-dataset?dataset_name=${deleteGroup}&userID=${user.uid}`,
        // `http://localhost:8000/delete-dataset?dataset_name=${deleteGroup}&userID=${user.uid}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        console.log("Dataset deleted successfully", deleteGroup);
        setDeleteGroup("");
        window.location.reload();
      } else {
        console.log("Error fetching scraped data");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`group-section ${isExpanded ? "expanded" : ""}`}>
      <DeleteGroupModal
        isOpen={deleteGroupModal}
        onClose={() => setDeleteGroupModal(false)}
        groupName={deleteGroup}
        handleDelete={handleGroupDelete}
      />
      <div className="group-header" onClick={handleHeaderClick}>
        <span className="group-name">{groupName}</span>
        <div className="group-info">
          <span className="group-size">results: {results.length}</span>
          <span className="group-date">{formattedDate}</span>
          <span className="expand-icon">{isExpanded ? "▼" : "▶"}</span>
          <span>
            <button
              className="delete-btn"
              onClick={() => {
                setDeleteGroupModal(true);
                setDeleteGroup(groupName);
              }}
              title="Delete"
            >
              <img src={deleteIcon} alt="delete" />
            </button>
          </span>
        </div>
      </div>

      {isExpanded && (
        <div className="group-content">
          <div className="group-content-inner">
            <div className="results-header">
              <h4>Name</h4>
              <h4>Email(s)</h4>
              <h4>Company</h4>
            </div>
            <div className="results-list">
              {results.map((value, index) => (
                <ResultEntry
                  key={index}
                  name={value.name}
                  email={value.email_formats}
                  company={`@${value.company}`}
                  profile_link={value.profile_link}
                  profile_img={value.profile_image}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ResultEntry = ({ name, email, company, profile_link, profile_img }) => {
  return (
    <div className="result-entry">
      <a
        className="result-info name"
        title={name}
        href={profile_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="profile-img"
          src={profile_img ? profile_img : defaultImg}
          alt="profile"
        />
        <div>{name}</div>
      </a>
      <div className="result-info">
        <div className="email-formats">{email[0]}</div>
      </div>
      <div className="result-info" id="company" title={company}>
        {company}
      </div>
    </div>
  );
};

const ScrapedResultsList = ({ user, scrapedData }) => {
  const [groupedResults, setGroupedResults] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedGroups, setExpandedGroups] = useState(new Set());
  const [deleteGroupModal, setDeleteGroupModal] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  useEffect(() => {
    // Group the results by dataset_name
    const grouped = {};
    Object.entries(scrapedData).forEach(([groupName, results]) => {
      grouped[groupName] = {
        date: results[0]?.date_added, // Use the first result's date as group date
        results: results,
      };
    });
    setGroupedResults(grouped);
  }, [scrapedData]);

  const toggleGroup = (groupName) => {
    setExpandedGroups((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(groupName)) {
        newSet.delete(groupName);
      } else {
        newSet.add(groupName);
      }
      return newSet;
    });
  };

  // Filter and sort groups based on search query and date
  const filteredGroups = Object.entries(groupedResults)
    .filter(([groupName, data]) => {
      if (!searchQuery) return true;

      const groupMatches = groupName.toLowerCase().includes(searchQuery);
      const resultsMatch = data.results.some(
        (result) =>
          result.name?.toLowerCase().includes(searchQuery) ||
          result.email_formats?.some((email) =>
            email.toLowerCase().includes(searchQuery)
          ) ||
          result.company?.toLowerCase().includes(searchQuery)
      );

      return groupMatches || resultsMatch;
    })
    .sort(([, a], [, b]) => {
      // Sort by date, newest first
      if (!a.date || !b.date) return 0;

      const dateA = new Date(a.date.year, a.date.month - 1, a.date.day);
      const dateB = new Date(b.date.year, b.date.month - 1, b.date.day);

      return dateB - dateA;
    });

  return (
    <div className="scraped-results-list">
      <div className="information-bar">
        <form className="search" onSubmit={(e) => e.preventDefault()}>
          <input
            placeholder="search by email, name, company, etc"
            onChange={handleSearch}
          />
        </form>
        <SaveToCSV
          data={Object.values(groupedResults).flatMap((g) => g.results)}
          filename="scraped_results"
        />
      </div>

      <div className="result-container">
        {filteredGroups.length === 0 ? (
          <div className="no-results">No results found | (っ °Д °;)っ</div>
        ) : (
          filteredGroups.map(([groupName, data]) => (
            <GroupRow
              user={user}
              key={groupName}
              groupName={groupName}
              date={data.date}
              results={data.results}
              isExpanded={expandedGroups.has(groupName)}
              onToggle={() => toggleGroup(groupName)}
              deleteGroup={deleteGroup}
              setDeleteGroup={setDeleteGroup}
              deleteGroupModal={deleteGroupModal}
              setDeleteGroupModal={setDeleteGroupModal}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ScrapedResultsList;
