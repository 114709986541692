import React, { useState } from "react";
import Modal from "./Modal";
import "./css/FileUploadModal.css";
import "./css/DeleteGroupModal.css";

const DeleteGroupModal = ({
  isOpen,
  onClose,
  groupName,
  handleDelete,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="delete-confirmation">
        <h2>Are you sure?</h2>
        <p><strong>you are about to permanently delete "{groupName}"</strong></p>
        <p>This item will be deleted immediately. You can't undo this action.</p>
        <div className = 'delete-buttons'>
          <button onClick={onClose} className="modal-cancel-btn">
            Cancel
          </button>
          <button onClick={handleDelete} className="modal-delete-btn">
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteGroupModal;
